import React, { useState, useEffect } from 'react';
import { Input, Textarea } from '@chakra-ui/react';
import TextAreaComponent from './TextArea';

const Custom = (props) => {
  const {
    onFocus,
    onChange,
    onBlur,
    value,
    defaultValue,
    children,
    type,
    textarea,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState(defaultValue);
  const [mounted, setMounted] = useState(false);
  const handleUpdateInput = (val) => {
    onChange(val);
    setLocalValue(val);
  };

  useEffect(() => {
    if (!mounted && value) {
      setLocalValue(value);
      setMounted(true);
    }
  }, [value]);

  return textarea ? (
    <Textarea
      {...rest}
      onChange={(e) => handleUpdateInput(e.target.value)}
      //   onBlur={() => {
      //     onBlur && onBlur();
      //   }}
      onFocus={() => {
        onFocus && onFocus();
      }}
      value={localValue}
      required={false}
      isRequired={false}
      minWidth={type === 'number' ? '100px' : '200px'}
      type={type}
      cursor='not-allowed'
      variant='filled'
      isReadOnly={true}
      height='200px'
      resize={true}
    />
  ) : (
    <Input
      {...rest}
      onChange={(e) => handleUpdateInput(e.target.value)}
      //   onBlur={() => {
      //     onBlur && onBlur();
      //   }}
      onFocus={() => {
        onFocus && onFocus();
      }}
      value={localValue}
      required={false}
      isRequired={false}
      minWidth={type === 'number' ? '100px' : '200px'}
      type={type}
      cursor='not-allowed'
      variant='filled'
      isReadOnly={true}
    />
  );
};

export default Custom;
