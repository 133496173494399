import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton, Text } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const columns = [
  {
    Header: 'First Name',
    id: 'edit',
    accessor: 'first_name',
    Cell: ({ row, value }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button colorScheme='blue' variant='link' justifyContent='flex-start'>
          {value}
        </Button>
      </LinkButton>
    ),
  },
  {
    Header: 'Last Name',
    id: '',
    accessor: 'last_name',
    Cell: ({ row, value }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button colorScheme='blue' variant='link' justifyContent='flex-start'>
          {value}
        </Button>
      </LinkButton>
    ),
  },
  {
    Header: 'Email',
    id: '',
    accessor: 'email',
    Cell: ({ row, value }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button colorScheme='blue' variant='link' justifyContent='flex-start'>
          {value}
        </Button>
      </LinkButton>
    ),
  },
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: ({ value }) => {
      return <Text>{dayjs(value).format('DD.MM.YYYY')}</Text>;
    },
  },
];

const List = () => {
  const filters = useMemo(
    () => [
      // {
      //    name: 'from',
      //    component: 'date',
      //    placeholder: 'From'
      // },
      // {
      //    name: 'isAdmin',
      //    component: 'bool',
      //    label: 'Is Admin'
      // },
      // {
      //    name: 'tester',
      //    component: 'select',
      //    placeholder: 'Tester',
      //    options: [{ name: 'yyuss', id: 1 }],
      //    labelKey: 'name',
      //    valueKey: 'id'
      // }
    ],
    []
  );

  return (
    <ListWrapper
      filters={filters}
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      handleRowSelection={(rows) => console.log(rows)}
    />
  );
};

export default List;
