import React from "react";

import { EditView, Field, AutoFields } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const EditForm = () => {
  const { type } = useParams();

  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "award_type",
            component: "text",
            label: "Award Type",
            disabled: true,
          },
          {
            name: "retailer_number",
            component: "text",
            label: "Retailer Number",
            disabled: true,
          },
          {
            name: "retailer_name",
            component: "text",
            label: "Retailer Name",
            disabled: true,
          },
          {
            name: "retailer_branch",
            component: "select",
            label: "Retailer Branch",
            valueKey: "id",
            labelKey: "name",
            isDisabled: true,
          },
          {
            name: "retailer_address",
            component: "text",
            label: "Retailer Address",
            disabled: true,
          },
          {
            name: "retailer_postcode",
            component: "text",
            label: "Retailer Postcode",
            disabled: true,
          },
          {
            name: "turnover",
            component: "text",
            label: "Turnover",
            disabled: true,
          },
          {
            name: "compliant",
            component: "text",
            label: "Compliant",
            disabled: true,
          },
          {
            name: "why_winner",
            component: "textarea",
            label: "Why Winner",
            disabled: true,
          },
          {
            name: "above_beyond",
            component: "textarea",
            label: "Above and Beyond",
            disabled: true,
          },
          {
            name: "created_at",
            component: "text",
            label: "Date Submitted",
            disabled: true,
          }
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
