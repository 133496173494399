import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Text } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'Customer | Branch',
        id: 'edit',
        accessor: 'id',
        Cell: ({ row }) => (
            <LinkButton
                to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
                <Button
                    colorScheme='blue'
                    variant='link'
                    justifyContent='flex-start'
                >
                    {row.original.retailer_number} | {row.original.retailer_branch.name}
                </Button>
            </LinkButton>
        ),
    },
    {
        Header: 'Retailer Name',
        accessor: "retailer_name"
    },
    {
        Header: "Award Type",
        accessor: "award_type",
    },
    {
        Header: "Date Submitted",
        accessor: "created_at"
    }
];

const List = () => {

    return (
        <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
