const modelName = 'Go Local Awards'
const modelNameSingular = 'Go Local Award'
const endpoint = '/go-local-awards'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
