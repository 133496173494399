import React from 'react';
import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import { Input } from '@chakra-ui/react';
import dayjs from 'dayjs';

const EditForm = () => {
  const { type } = useParams();

  const fields = [
    {
      group: 'Feedback Information',
      tab: 'Feedback Information',
      fields: [
        {
          name: 'first_name',
          component: 'custom',
          label: 'Name',
        },
        {
          name: 'last_name',
          component: 'custom',
          label: 'Image',
        },
        {
          name: 'email',
          component: 'custom',
          label: 'Email',
        },
        {
          name: 'phone_number',
          component: 'custom',
          label: 'Phone',
        },
        {
          name: 'feedback',
          component: 'custom',
          label: 'Feedback',
          textarea: true,
        },

        {
          name: 'created_at',
          label: 'Date',
          component: ({ value }) => {
            return (
              <Input
                bg='rgb(237, 242, 247)'
                border='0px'
                value={dayjs(value).format('DD.MM.YYYY')}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
